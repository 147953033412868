// Custom.scss
// Option A: Include all of Bootstrap

// Include any default variable overrides here (though functions won't be available)

@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";

// Then add additional custom code here
@mixin color-scheme($name) {
  @media (prefers-color-scheme: #{$name}) {
    @content;
  }
}

body {
  background: #eee;
  color: #222;
  a, a:hover, a:visited {
    color: #1e2125;
  }
  @include color-scheme(dark) {
    // Insert dark mode styles here
    background: #222;
    color: #ddd;
    a, a:hover, a:visited {
      color: #adb5bd;
    }
  }
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
}
.wallie-landing {
  background-size: cover;
  background-image: url("./marek-piwnicki-JNiYQHi5Hjc-unsplash.jpg");
}
.wallie-404 {
  background-size: cover;
  background-image: url("./annie-theby-EXCeGbyolPY-unsplash.png");
}
.wallie-404-sh-title {
  text-shadow: 0 0 20px #eee ;
  @include color-scheme(dark) {
    // Insert dark mode styles here
    text-shadow: 0 0 20px #1e2125 ;
  }
}
.wallie-404-sh-text {
  text-shadow: 0 0 5px #eee ;
  @include color-scheme(dark) {
    // Insert dark mode styles here
    text-shadow: 0 0 20px #1e2125 ;
  }
}
